import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby"
import loadable from "@loadable/component"
import _ from "lodash";

import {OfficeList} from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";

import TeamCard from "gatsby-theme-starberry-lomondgroup/src/components/OurTeam/TeamCard"
import "gatsby-theme-starberry-lomondgroup/src/components/OurTeam/OurTeam.scss"




const ReactPaginate = loadable(() => import("react-paginate"));
const Select = loadable(() => import("react-select"));

const OurTeam = ({ content }) => {

    const data = useStaticQuery(graphql`
        query TeamQueryCustom{
        glstrapi {
            teamsConnection(where: {publish:true}) {
                aggregate {
                    count
                }
            }

            teams(sort:"rank:asc",where:{publish:true}) {
                id
                name
                slug
                image {
                    alternativeText
                    url
                }
                title
                video_url
                mobile_no
                designation
                email
                content
                category
                show_on_teams
                imagetransforms
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
                offices {
                    id
                    name
                    slug
                }
            }
        }
    }`)

    var teams = data.glstrapi?.teams;
    var teams_count = data.glstrapi?.teamsConnection?.aggregate?.count;
    var teams_cat = _.filter(teams, 'category')
    //console.log('teamxxs', teams_cat)
    const categories =  _.uniq(_.map(teams_cat, 'category'));


    const [set, setClass] = useState(false);
    const handleShow = () => {
        setClass(!set);
    }

    const{loading, error, data:branch_list} = OfficeList();
    const[category, setCategory] = useState("");
    const[branch, setBranch] = useState("")
    const[branch_name, setBranchName] = useState("")

    let [teamList, setTeamList] = useState([]);
    let [teamListPagination, setTeamListPagination] = useState([]);

    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 20;
    const [itemOffset, setItemOffset] = useState(0);

    var options = [
        { label: 'All People', value: '' }
    ]

    var branch_options = [
        { label: 'All Branches', value: '' }
    ]

    // const applyCategory = (filt, type) => {

    //     var teams_list = teams;
    //     var team_filter = [];
    //     if (type === "category") {
    //         teams_list = teams.length > 0 && teams.filter((item => item.category === filt))
    //     }

    //     setTeamList(teams_list);
    // }

    useEffect(() => {
        var teams_list = teams.filter(item => item.show_on_teams !== false);
        var team_filter = [];

        if (category) {
            teams_list = teams_list.length > 0 && teams_list.filter((item => item.category === category))
        }

        if (branch) {
            teams_list.length > 0 && teams_list.map((item) => {
                if(item.offices.length > 0 && item.offices.some(item2 => item2.id === branch)){
                    team_filter.push(item)
                }
            })
            teams_list = team_filter
        }

        if (category || branch) {
            setItemOffset(0);
        }

        setTeamList(teams_list);
        setTeamListPagination(teams_list)

    },[teams, category, branch])


    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setTeamListPagination(teamList.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(teamList.length / itemsPerPage));
        
    }, [itemOffset, teamList]);

    const handlePageClick = (event) => {
        //console.log("blog_test", event)

        const newOffset = (event.selected * itemsPerPage) % teamList.length;

        setTimeout(() => {
            window.scrollTo(0, 500);
        }, 500)

        //console.log("newOffset", newOffset)
        setItemOffset(newOffset);
    };


    //console.log("categories", categories);

    _.map(categories, (data) => {
        if(data){
            if(process.env.GATSBY_SITE_NAME === "Thornley Groves"){
                var cat_name = data.replace('General', 'Head Office')
                options.push({ label: cat_name.replace(/_/g, ' '), value: data })
            } else{
                options.push({ label: data.replace(/_/g, ' '), value: data })
            }
            
        }
        
    })

    branch_list?.offices?.length > 0 && branch_list?.offices.filter(item => item.slug !== "students" && item.slug !== "property-management-and-accounts").map((item) => {
        if(item){
            branch_options.push({ label: item.name, value: item.id })
        }
    })

    //console.log("GATSBY_SITE_NAME", process.env.GATSBY_SITE_NAME);

    return (
        <React.Fragment>
            <section className="our-team location-block">
                <Container>
                    <Row>
                        <Col>

                            {content && <ContentModule Content={content} /> }

                            {
                                <section className="category">
                                    <div className="category-wrap new-wrap">
                                        <button onClick={handleShow}>{category ? category.replace(/_/g,' ') : "All People"}
                                            <i className="icon-dropdown"></i>
                                        </button>
                                    </div>
                                    <div id="filter-link" className={set ? "category-wrap news-category open-category" : "category-wrap news-category"}>
        
                                        {options?.map((item, index) => (
                                            <button key={index} className={category === item.value.replace(/_/gi, ' ') ? "link-btn active" : "link-btn"} onClick={(event) => {
                                                setCategory(item.value)
                                            }}>{item.label.replace(/_/gi, ' ')} </button>
                                        ))}
                                    </div>
                                </section>
                            }

                            <div className="filter-wrapper">
                                    {/* {process.env.GATSBY_SITE_NAME === "D. J. Alexander" ?
                                        <span className="sm-text">Senior Management</span>
                                        :
                                        <span className="sm-text">Showing {teamList.length} {(teamList.length > 1) ? 'Staff members' : 'Staff member'}</span>
                                    } */}
                                    <span className="sm-text">Showing {teamList.length} people</span>

                                    {/* {(options.length > 1) && 
                                    <div className="filter-boxes">
                                        <strong className="filter-box">Filter by:</strong>

                                        {(process.env.GATSBY_SITE_NAME === "Thornley Groves" || process.env.GATSBY_SITE_NAME === "Beals" || process.env.GATSBY_SITE_NAME === "Linley and Simpson") && 
                                            branch_options.length > 0 && 
                                            <Select
                                                options={branch_options}

                                                placeholder="All Branches"
                                                onChange={(e) => {
                                                    //console.log('teamxxs', e)
                                                    setBranch(e.value, "branch")
                                                }}
                                                className="react-select filter-select me-0 me-md-3"
                                                classNamePrefix="react-select" 
                                                //menuIsOpen={true}
                                                
                                            />
                                        }
                                        
                                        {options.length > 0 &&
                                            <Select
                                                options={options}

                                                placeholder="All Categories"
                                                onChange={(e) => {
                                                    //console.log('teamxxs', e)
                                                    setCategory(e.value)
                                                }}
                                                className="react-select filter-select"
                                                classNamePrefix="react-select" 
                                                //menuIsOpen={true}
                                                
                                            />
                                        }
                                    </div>
                                    } */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="team-details">
                                {teamListPagination?.length > 0 ?
                                    teamListPagination?.map((team, index) => (
                                        <TeamCard key={index} TeamList={team} />
                                    ))
                                :
                                    (category || branch) ?
                                    <div className="col-12 text-center">No teams found.</div>
                                    :   ""                                
                                }
                            </div>

                            {pageCount > 1 &&
                                <div className="pagination-section">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="< previous"
                                        renderOnZeroPageCount={null}
                                        className="pagination"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="prev page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="next page-item"
                                        nextLinkClassName="page-link"
                                        activeClassName="active"
                                    />
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default OurTeam;
