import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
import { PageLinks } from "gatsby-theme-starberry-lomondgroup/src/common/page-links";

import "gatsby-theme-starberry-lomondgroup/src/components/LocationBlock/LocationBlock.scss";
import BoxBlock from "gatsby-theme-starberry-lomondgroup/src/components/BoxBlock/Box"
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";
import {AreaList} from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query"
import "./location-block.scss";

const Location = ({Module}) => {

    const data = useStaticQuery(graphql`
        query OfficeListCustom{
            glstrapi {
                areas{
                    id
                    area_name
                }

                offices(sort:"rank:ASC", where:{publish:true}) {
                    id
                    slug
                    name
                    address
                    mobile
                    email
                    office_crm_id
                    areas{
                        id
                        area_name
                    }
                    letting_info {
                        mobile
                        longitude
                        latitude
                        email
                        address
                    }
                    image{
                        url
                        alternativeText
                    }
                    imagetransforms
                    office_type
                    ggfx_results {
                        id
                        content_type
                        transforms
                        src_import_url
                        src_cftle
                        field
                    }
                    sort
                }
            }
        }
    `)
    
   
    const[office_list, setOfficeList] = useState(data.glstrapi.offices);
    const[filter_area, setFiltArea] = useState('');
    const[searchArea, setSearchArea] = useState('');
    const [isFirstActive, setIsFirstActive] = useState(true);

    //const{loading, error, data} = OfficeList();
    //const{data:area_data} = AreaList();
    var area_data = data.glstrapi.areas

    //console.log("area_data", area_data);

    const [set, setClass] = useState(false);
    const handleShow = () => {
        setClass(!set);
    }

    const{loading, error, data:branch_list} = AreaList();
    const[category, setCategory] = useState("");
    const[branch, setBranch] = useState("")
    const[branch_name, setBranchName] = useState("")

    // var branch_options = [
    //     { label: 'All Offices', value: '' }
    // ]

    var office_data = data.glstrapi.offices;

    // useEffect(() => {
    //     data?.offices?.length > 0 && setOfficeList(data?.offices);
    // }, [data])

    useEffect(() =>{
        //console.log("filter_area", filter_area);
        
        if(office_data && office_data?.length > 0){
            var all_office = office_data;
            var office_list =  [...all_office];

            if(filter_area){
                //console.log("test_address ==> filter_area", filter_area)
                var filter_branch = []
                office_list?.map((item, index) => {
                    if(filter_area?.value){
                        if(item?.areas.length > 0 && item?.areas?.some(item2 =>  item2.id === filter_area?.value)){
                            filter_branch.push(item);
                        }
                    } else{
                        filter_branch.push(item);
                    }
                })

                office_list = filter_branch
            } 
            if(searchArea){
                office_list = office_list.filter(item => item.address.toLowerCase().includes(searchArea.toLowerCase()) || item.name.toLowerCase().includes(searchArea.toLowerCase()))

                //console.log("test_address ==> searchArea", searchArea, office_list)
            }

            setOfficeList(office_list)
        }
    },[filter_area, office_data, searchArea])

    const options =  [{ value: '', label: 'All Offices' }]

    area_data?.length > 0 && area_data?.map((item, index) => {
        options.push({value: item.id, label: item.area_name})
    })


    //console.log("filter_area ==>", filter_area)

    return (
        <React.Fragment>
            <section className="location-block">
                <Container>
                    <Row>
                        <Col>                            
                            {Module.description && <ContentModule Content={Module.description} />}

                            <section className="category">
                                <div className="category-wrap new-wrap">
                                    <button onClick={() => {handleShow();}}>
                                        {branch_name ? branch_name.replace(/_/g,' ') : "All Offices"}
                                        <i className="icon-dropdown"></i>
                                    </button>
                                </div>
                                <div id="filter-link" className={set ? "category-wrap news-category open-category" : "category-wrap news-category"}>
                                    {/* <button className={branch === "" ? "link-btn active" : "link-btn"} onClick={(event) => {
                                        setBranch(""); 
                                        //applyCategory("all_guides"); newClass(event);
                                    }}>All Branches</button> */}
    
                                    {options?.map((item, index) => (
                                        <>
                                        {console.log(index)}
                                        <button key={index} className={filter_area.value === item.value.replace(/_/gi, ' ') || (isFirstActive && index === 0) ? "link-btn active" : "link-btn"} onClick={(event) => {
                                            // setBranch(item.value.replace(/_/gi, ' ')); 
                                            setBranchName(item.label.replace(/_/gi, ' '))
                                            setFiltArea(item); 
                                            //newClass(event);
                                            handleShow();
                                            if (isFirstActive) {
                                                setIsFirstActive(false);
                                            }
                                        }}>{item.label.replace(/_/gi, ' ')} </button>
                                        </>
                                    ))}
                                </div>
                            </section>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {office_list?.length > 0 && 
                            <div className="location-block-wrapper">
                                {office_list.map((item, index) => (
                                    // <BoxBlock key={index} BoxList={item} />
                                    <div className="location-card">
                                        <div className="location-card-name">
                                            <h3>{item?.name}</h3>
                                            <div>
                                                <Link to={`/${PageLinks.branch_finder}/${item.slug}/`}>View Office Details</Link>
                                            </div>
                                        </div>
                                        <div className="location-card-ofz">
                                            {
                                                item?.office_type &&
                                                <div className="location-card-ofz-title">{item?.office_type.replace('_',' ')}</div>
                                            }
                                            {
                                                item?.mobile &&
                                                <div className="location-card-ofz-mobile">
                                                    <a href={`tel:${item?.mobile}`}>{item?.mobile}</a>
                                                </div>
                                            }
                                            {
                                                item?.email &&
                                                <div className="location-card-ofz-email">
                                                    <a href={`mailto:${item?.email}`}>{item?.email}</a>
                                                </div>
                                            }
                                        </div>
                                        
                                        {(item?.letting_info?.mobile ||  item?.letting_info?.email) && 
                                        <div className="location-card-ofz-address">
                                            {
                                                item?.office_type &&
                                                <div className="location-card-ofz-title">Lettings</div>
                                            }

                                            {
                                                item?.letting_info?.mobile &&
                                                <div className="location-card-ofz-mobile">
                                                    <a href={`tel:${item?.letting_info?.mobile}`}>{item?.letting_info?.mobile}</a>
                                                </div>
                                            }
                                            {
                                                item?.letting_info?.email &&
                                                <div className="location-card-ofz-email">
                                                    <a href={`mailto:${item?.letting_info?.email}`}>{item?.letting_info?.email}</a>
                                                </div>
                                            }

                                            {/* {
                                                item?.address &&
                                                <ContentModule Content={item?.address} />
                                            } */}
                                        </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default Location;
